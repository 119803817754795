import { ComponentProps, Props, StyleProps } from '../../Interfaces/Text';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const setTypeStyle = (props: Props) => {
  switch (props.type) {
    case 'body-title':
      return css`
        font-size: 14px;
        font-weight: bold;
        letter-spacing: -0.18px;
      `;
    case 'body-text':
      return css`
        font-size: 14px;
        font-weight: normal;
        letter-spacing: -0.28px;
      `;
    case 'sub-title':
      return css`
        font-size: 13px;
        font-weight: bold;
        letter-spacing: -0.28px;
      `;
    case 'sub-text':
      return css`
        font-size: 12px;
        font-weight: normal;
        letter-spacing: -0.28px;
      `;
    case 'title1':
      return css`
        font-size: 20px;
        font-weight: bold;
        letter-spacing: -0.45px;
      `;
    case 'title2':
      return css`
        font-size: 17px;
        font-weight: bold;
        letter-spacing: -0.43px;
      `;
    case 'title3':
      return css`
        font-size: 15px;
        font-weight: bold;
        letter-spacing: -0.23px;
      `;
    case 'title4':
      return css`
        font-size: 14px;
        font-weight: bold;
        letter-spacing: -0.23px;
      `;
    case 'custom':
      return css`
        font-size: ${props.fontSize || '13px'};
        font-weight: ${props.fontWeight || 'normal'};
        letter-spacing: ${props.letterSpacing || 'normal'};
        line-height: ${props.lineHeight};
      `;
    default:
      return css`
        color: #38373e;
        font-size: 13px;
        font-weight: normal;
      `;
  }
};

export const BaseText = styled.span<Props>`
  display: inline;
  transition-duration: 0.2s;
  color: ${(props) => props.color || 'var(--primary-black)'};
  text-decoration: ${(props) => (props.onClick ? 'underline' : '')}};
  cursor: ${(props) => (props.onClick ? 'pointer' : '')}};
  ${setTypeStyle}
`;
