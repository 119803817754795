import { Props } from '../../Interfaces/Text';
import { BaseText } from '../../Styles/Text';
import React from 'react';

/**
 * 단순 텍스트 표시 컴포넌트입니다. 주어진 props를 기반으로 스타일이 적용된 텍스트를 렌더링합니다.
 *
 * @param {Object} props - 텍스트와 관련된 props
 * @param {React.ReactNode} props.children - 표시할 텍스트 또는 React 요소
 * @param {'title' | 'body' | 'descript' | 'link' | 'custom'} props.type - 텍스트 타입 (예: 'title', 'body')
 * @param {CSSProperties['fontSize']} [props.fontSize] - 텍스트의 폰트 크기 (예: '16px', '1em')
 * @param {CSSProperties['fontFamily']} [props.fontFamily] - 텍스트에 사용할 폰트 패밀리
 * @param {CSSProperties['fontWeight']} [props.fontWeight] - 폰트 두께
 * @param {CSSProperties['color']} [props.color] - 텍스트 색상 (CSS 색상 값)
 *
 * @returns {React.ReactElement} 스타일이 적용된 텍스트 컴포넌트
 */
const Text = ({ children, type, className, fontSize, fontFamily, fontWeight, color, onClick, lineHeight }: Props) => {
  return (
    <BaseText
      className={className}
      type={type}
      fontSize={fontSize}
      fontFamily={fontFamily}
      fontWeight={fontWeight}
      color={color}
      lineHeight={lineHeight}
      onClick={onClick}
    >
      {children}
    </BaseText>
  );
};

export default Text;
