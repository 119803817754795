/**
 * App Index
 * @version : 1.0
 * @author  : UX (gilhyun@gmail.com)
 */
import React from 'react';
import ReactDOM from 'react-dom';
import 'resize-observer-polyfill/dist/ResizeObserver.global';
import GoogleFontLoader from 'react-google-font-loader';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import rootReducer from './modules/Reducer';
import { GlobalStyle } from './modules/Styled/GlobalStyles';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import './constants/Locales/i18n';

const store = createStore(rootReducer, composeWithDevTools());

ReactDOM.render(
  <>
    <div className={'c-drag-area'}></div>
    <GoogleFontLoader fonts={[{ font: 'Noto+Sans+KR', weights: [300, 400, 700, 900] }]} />
    <GlobalStyle />
    <Provider store={store}>
      <App />
      {/* <React.StrictMode>
                <App />
            </React.StrictMode> */}
    </Provider>
    <ToastContainer
      position="bottom-center"
      autoClose={500}
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      transition={Slide}
      limit={1}
    />
  </>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
