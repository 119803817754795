/**
 * App
 * @version : 1.0
 * @author  : UX (gilhyun@gmail.com)
 */
import React, { Suspense, lazy } from 'react';

import { Route, Switch, BrowserRouter } from 'react-router-dom';
import PrivateRoute from './components/common/PrivateRoute';
import Loading from './components/common/Loading';
import { NlpPageTypePrefix } from './constants/Types';

const Landingpage = lazy(() => import('./pages/Landingpage'));
const FromMarket = lazy(() => import('./pages/FromMarket'));
const Login = lazy(() => import('./pages/Auth/Login'));
const SecondaryLogin = lazy(() => import('./pages/Auth/SecondaryLogin'));
const Logout = lazy(() => import('./pages/Auth/Logout'));
const Agree = lazy(() => import('./pages/Auth/Agree'));
const ExternalLogin = lazy(() => import('./pages/Auth/ExternalLogin'));
const ExternalLanging = lazy(() => import('./pages/Auth/ExternalLanding'));
const Bot = lazy(() => import('./pages/Bot'));
const Template = lazy(() => import('./pages/Template'));
const Scenario = lazy(() => import('./pages/Scenario'));
const Preview = lazy(() => import('./pages/Preview'));
const Error404 = lazy(() => import('./pages/Error404'));
const Setting = lazy(() => import('./pages/Setting'));
const NlpSetting = lazy(() => import('./pages/NlpSetting'));

const ButtonDesigner = lazy(() => import('./pages/ButtonDesigner'));

const Mypage = lazy(() => import('./pages/MyPage'));
const ChangePassword = lazy(() => import('./pages/ChangePassword'));

const Deploy = lazy(() => import('./pages/Deploy'));
const BotDeploy = lazy(() => import('./pages/BotDeploy'));
const Consulting = lazy(() => import('./pages/Counseling'));
const BotConsulting = lazy(() => import('./pages/BotCounseling'));
const History = lazy(() => import('./pages/History'));

const AuthList = lazy(() => import('./pages/AuthList'));
const BotAuthList = lazy(() => import('./pages/BotAuthList'));
const RatingList = lazy(() => import('./pages/RatingList'));
const SessionList = lazy(() => import('./pages/SessionList'));
const SyncList = lazy(() => import('./pages/SyncList'));
const StatList = lazy(() => import('./pages/StatList'));

const Statistics = lazy(() => import('./pages/Statistics'));
const BotStatistics = lazy(() => import('./pages/BotStatistics'));
const UserStatistics = lazy(() => import('./pages/UserStatistics'));
const ChatStatistics = lazy(() => import('./pages/ChatStatistics'));
const ScenarioStatistics = lazy(() => import('./pages/ScenarioStatistics'));
const ApiDocs = lazy(() => import('./pages/ApiDocs'));
const PostCode = lazy(() => import('./pages/PostCode'));
const SurveyForm = lazy(() => import('./pages/SurveyForm'));

const Analyzation = lazy(() => import('./pages/Analyztion'));
const Participation = lazy(() => import('./pages/Participation'));
const TransmissionHistory = lazy(() => import('./pages/TransmissionHistory'));
const SurveyList = lazy(() => import('./pages/SurveyList'));

// 개발 테스트용 페이지
const Playground = lazy(() => import('./pages/Playground'));

//readonly 페이지 수정불가 봇을 볼 수 만 있음
const ReadonlyScenario = lazy(() => import('./pages/Readonly'));
const TempEditor = lazy(() => import('./pages/TempEditor'));

const Health = {};
const App = () => {
  return (
    <>
      <Suspense fallback={<Loading />}>
        <BrowserRouter>
          <Switch>
            <Route exact path={`/`} component={Landingpage} />
            <Route exact path={`/market`} component={FromMarket} />
            <Route exact path={`/auth/login`} component={Login} />
            <Route exact path={`/auth/login/:conlogout`} component={Login} />
            <Route exact path={`/auth/secondarylogin/:memberId`} component={SecondaryLogin} />
            {/* <Route exact path={`/auth/join`} component={Join} /> */}
            <Route exact path={`/auth/agree`} component={Agree} />
            {/* <Route exact path={`/auth/find/id`} component={FindId} /> */}
            {/* <Route exact path={`/auth/find/pass`} component={FindPass} /> */}
            <Route exact path={`/playground`} component={Playground} />
            <Route exact path={`/external/login/:siteId/:botId/:accessKey`} component={ExternalLogin} />
            <Route
              exact
              path={`/external/login/:memberId/:botId/:accessKey/:accessToken`}
              component={ExternalLanging}
            />
            <Route exact path={`/postcode/:bSId`} component={PostCode} />
            <PrivateRoute exact path={`/logout`} component={Logout} exceptionPayment={true} />
            <PrivateRoute exact path={`/mypage`} component={Mypage} exceptionPayment={true} />
            <PrivateRoute exact path={`/doc/api/:guide`} component={ApiDocs} exceptionPayment={true} />
            <PrivateRoute exact path={`/bot`} component={Bot} exceptionPayment={true} />
            <PrivateRoute exact path={`/template`} component={Template} />
            <PrivateRoute exact path={`/bot/:botId`} component={Scenario} />
            <PrivateRoute exact path={`/bot/:botId/:scenario`} component={Scenario} />
            <PrivateRoute exact path={`/preview/:botId`} component={Preview} />
            <PrivateRoute exact path={`/setting/:botId`} component={Setting} />
            <PrivateRoute exact path={`/button-designer`} component={ButtonDesigner} exceptionPayment={true} />
            <PrivateRoute exact path={`/deploy/bots/:botId`} component={Deploy} exceptionPayment={true} />
            <PrivateRoute exact path={`/deploy/:botId`} component={BotDeploy} />
            <PrivateRoute exact path={`/consulting`} component={Consulting} />
            <PrivateRoute exact path={`/consulting/:botId`} component={BotConsulting} />
            <PrivateRoute exact path={`/history`} component={History} exceptionPayment={true} />
            <PrivateRoute exact path={`/changepass`} component={ChangePassword} />
            <PrivateRoute exact path={`/authlist`} component={AuthList} exceptionPayment={true} />
            <PrivateRoute exact path={`/authlist/:botId`} component={BotAuthList} />
            <PrivateRoute exact path={`/ratinglist`} component={RatingList} exceptionPayment={true} />
            <PrivateRoute exact path={`/sessionlist`} component={SessionList} exceptionPayment={true} />
            <PrivateRoute exact path={`/synclist`} component={SyncList} exceptionPayment={true} />
            <PrivateRoute exact path={`/statlist`} component={StatList} />
            <PrivateRoute exact path={`/statistics/:botId`} component={BotStatistics} />
            <PrivateRoute exact path={`/statistics/bots/:botId`} component={Statistics} />
            <PrivateRoute exact path={`/statistics/user/:botId`} component={UserStatistics} />
            <PrivateRoute exact path={`/statistics/chat/:botId`} component={ChatStatistics} />
            <PrivateRoute exact path={`/statistics/scenario/:botId`} component={ScenarioStatistics} />

            <PrivateRoute exact path={`/participation`} component={Participation} exceptionPayment={true} />
            <PrivateRoute exact path={`/transmission/history`} component={TransmissionHistory} />
            <PrivateRoute exact path={`/analyzation`} component={Analyzation} exceptionPayment={true} />

            <PrivateRoute exact path={`/surveylist`} component={SurveyList} exceptionPayment={true} />

            <PrivateRoute exact path={`/readonly/:botId`} component={ReadonlyScenario} />
            <PrivateRoute exact path={`/readonly/:botId/:scenario`} component={ReadonlyScenario} />

            <PrivateRoute exact path={`/tempeditor/scenario/:scenario`} component={TempEditor} />

            <PrivateRoute
              exact
              path={`/nlp/:botId/:pageType(${Object.values(NlpPageTypePrefix).join('|')})`}
              component={NlpSetting}
            />
            <Route exact path={`/surveyform/:scenarioId/:blockId`} component={SurveyForm} />
            <Route path="*">
              <Error404 />
            </Route>
          </Switch>
        </BrowserRouter>
      </Suspense>
    </>
  );
};

export default App;
