import styled from 'styled-components';

const BotPageContainer = styled.div`
  position: fixed;
  overflow-y: auto;
  height: 100%;
  width: 100%;

  &.activeAgent {
    top: 54px;
    left: 305px;
    height: calc(100% - 54px);
    width: calc(100% - 120px);
  }
`;

export default BotPageContainer;
