/**
 * 캔버스 UI 스토어 컨트롤 훅
 * @version : 1.0
 * @author  : UX (gilhyun@gmail.com)
 */
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import {
  toggleScenarioList,
  openScenarioList,
  toggleVariableList,
  openVariableList,
  toggleLibraryPanel,
  openLibraryPanel,
  toggleBlockEditorPanel,
  openBlockEditorPanel,
  closeBlockPreviewPanel,
  toggleBlockPreviewPanel,
  openBlockPreviewPanel,
  setCanvasZoomLevel,
  setDimmed,
  setLockDimmed,
  // setTutorialDone,
  getDeployBot,
  getAuthBot,
  getConsultBot,
  getStatisticsBot,
  getSurveyFormWidth,
  setSurveyFormWidth,
  setFromMarket,
  getNlpBot,
  setErrorDialog,
} from '../modules/Reducer/CanvasUI';

export default function useCanvasUIActions() {
  const dispatch = useDispatch();

  /**
   * 시나리오 리스트 토글
   */
  const onEditorRefreshModal = useCallback(() => dispatch(toggleScenarioList()), [dispatch]);

  /**
   * 시나리오 리스트 토글
   */
  const onToggleScenarioList = useCallback(() => dispatch(toggleScenarioList()), [dispatch]);

  /**
   * 시나리오 리스트 오픈
   */
  const onOpenScenarioList = useCallback(() => dispatch(openScenarioList()), [dispatch]);

  /**
   * 변수설정 리스트 토글
   */
  const onToggleVariableList = useCallback(() => dispatch(toggleVariableList()), [dispatch]);

  /**
   * 변수설정 리스트 오픈
   */
  const onOpenVariableList = useCallback(() => dispatch(openVariableList()), [dispatch]);

  /**
   * 라이브러리 토글
   */
  const onToggleLibraryPanel = useCallback(() => dispatch(toggleLibraryPanel()), [dispatch]);

  /**
   * 라이브러리 오픈
   */
  const onOpenLibraryPanel = useCallback(() => dispatch(openLibraryPanel()), [dispatch]);

  /**
   * 블럭 에디터 토글
   */
  const onToggleBlockEditorPanel = useCallback(() => dispatch(toggleBlockEditorPanel()), [dispatch]);

  /**
   * 블럭 에디터 토글
   */
  const onOpenBlockEditorPanel = useCallback(() => dispatch(openBlockEditorPanel()), [dispatch]);

  /** 블럭 에디터 닫기 */
  const onCloseBlockEditorPanel = useCallback(() => dispatch(closeBlockPreviewPanel()), [dispatch]);

  /**
   * 블럭 프리뷰 토글
   */
  const onToggleBlockPreviewPanel = useCallback(() => dispatch(toggleBlockPreviewPanel()), [dispatch]);

  /**
   * 블럭 프리뷰 오픈
   */
  const onOpenBlockPreviewPanel = useCallback(() => dispatch(openBlockPreviewPanel()), [dispatch]);

  /**
   * 캔버스 줌 설정
   */
  const onSetCanvasZoomLevel = useCallback((zoonLevel: number) => dispatch(setCanvasZoomLevel(zoonLevel)), [dispatch]);

  /**
   * 딤드 설정
   */
  const onSetDimmed = useCallback((show: boolean) => dispatch(setDimmed(show)), [dispatch]);

  /**
   * 투명 딤드 설정
   */
  const onSetLockDimmed = useCallback((show: boolean) => dispatch(setLockDimmed(show)), [dispatch]);

  /**
   * 튜툐리얼 완료 설정
   */
  // const onSetTutorialDone = useCallback(() => dispatch(setTutorialDone()), [dispatch]);

  const onToggleDeployPage = useCallback(() => dispatch(getDeployBot()), [dispatch]);
  const onToggleConsultPage = useCallback(() => dispatch(getConsultBot()), [dispatch]);
  const onToggleStatisticsPage = useCallback(() => dispatch(getStatisticsBot()), [dispatch]);

  /**
   * 딤드 설정
   */
  const onSetSurveyFormWidth = useCallback((width: number) => dispatch(setSurveyFormWidth(width)), [dispatch]);

  const onSetFromMarket = useCallback(() => dispatch(setFromMarket()), [dispatch]);

  const onToggleAuthPage = useCallback(() => dispatch(getAuthBot()), [dispatch]);
  const onToggleNlpPage = useCallback(() => dispatch(getNlpBot()), [dispatch]);

  /**
   * 캔버스 줌 설정
   */
  const setErrorDialogDispatch = useCallback(
    (option: { open: boolean; type: string }) => dispatch(setErrorDialog(option)),
    [dispatch],
  );

  return {
    onEditorRefreshModal,
    onToggleScenarioList,
    onOpenScenarioList,
    onToggleVariableList,
    onOpenVariableList,
    onToggleLibraryPanel,
    onOpenLibraryPanel,
    onToggleBlockEditorPanel,
    onOpenBlockEditorPanel,
    onCloseBlockEditorPanel,
    onToggleBlockPreviewPanel,
    onOpenBlockPreviewPanel,
    onSetCanvasZoomLevel,
    onSetDimmed,
    onSetLockDimmed,
    // onSetTutorialDone,
    onToggleDeployPage,
    onToggleConsultPage,
    onToggleStatisticsPage,
    onSetSurveyFormWidth,
    onToggleAuthPage,
    onSetFromMarket,
    onToggleNlpPage,
    setErrorDialogDispatch,
  };
}
