import styled from 'styled-components';
import React, { useEffect, useRef, useState } from 'react';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import Select, { OptionTypeBase, components } from 'react-select';

/** 셀렉트 박스 컨테이너 */
const Container = styled.div<{ width?: string }>`
  ${(props) => (props.width ? 'width :' + props.width : '')}
`;

/** react-select 커스텀 스타일 */
const customStyles = (menuPlacement: 'auto' | 'bottom' | 'top') => {
  return {
    control: (styles: any, state: any) => ({
      ...styles,
      boxShadow: 'none',
      borderRadius: state.menuIsOpen ? (menuPlacement === 'bottom' ? '4px 4px 0 0' : '0 0 4px 4px') : '4px',
      minHeight: 33,
      height: '100%',
      borderColor: '#DDD',
      cursor: 'pointer',
    }),

    valueContainer: (styles: any, state: any) => ({
      ...styles,
      height: '100%',
      lineHeight: '20px',
    }),
    indicatorSeparator: (styles: any, state: any) => ({
      ...styles,
      display: 'none',
    }),
    dropdownIndicator: (styles: any, state: any) => ({
      ...styles,
      padding: '0 5px',
      fontSize: 21,
    }),
    clearIndicator: (styles: any, state: any) => ({
      ...styles,
      svg: {
        width: 15,
        height: 15,
      },
    }),
    menu: (styles: any, state: any) => ({
      ...styles,
      margin: menuPlacement === 'bottom' ? '0 1px 1px' : '1px 1px 0',
      borderRadius: menuPlacement === 'bottom' ? '0 0 4px 4px' : '4px 4px 0 0',
      boxShadow: '0 0 0 1px rgb(55, 125, 255)',
      width: 'calc(100% - 2px)',
      padding: 0,
      zIndex: 11,
      transition: 'all 0.2s',
    }),
    option: (styles: any, state: any) => ({
      ...styles,
      color: state.isDisabled ? '#989898' : 'var(--black)',
      backgroundColor: state.isDisabled ? '#f2f2f2' : state.isFocused ? 'rgb(233, 241, 254)' : '#fff',
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      padding: '0 0 0 15px',
      fontSize: 13,
      height: 30,
      lineHeight: '30px',
      ':hover': {
        backgroundColor: 'rgb(233, 241, 254)',
      },
      overflow: state.data.isOverflow ? 'none' : 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      transition: 'all 0.2s',
    }),
    noOptionsMessage: (styles: any, state: any) => ({
      ...styles,
      padding: 0,
    }),
  };
};

const SelectWrapper: React.FC<OptionTypeBase> = (options) => {
  const [menuPlacement, setMenuPlacement] = useState<'auto' | 'bottom' | 'top'>('bottom');
  const selectRef = useRef<HTMLDivElement>(null);
  /** Arrow 아이콘 */
  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <MdArrowDropDown />
      </components.DropdownIndicator>
    );
  };

  const onFocusHandle = () => {
    if (!selectRef?.current) return;
    const { y } = selectRef.current.getBoundingClientRect();
    if (window.innerHeight - y < 230) {
      setMenuPlacement('top');
    } else {
      setMenuPlacement('bottom');
    }
  };

  return (
    <Container
      width={options.width}
      onWheel={(e) => {
        e.stopPropagation();
      }}
      ref={selectRef}
    >
      <Select
        {...options}
        isSearchable={options.isSearchable || false}
        styles={customStyles(menuPlacement)}
        components={{ DropdownIndicator }}
        onChange={options.onChange}
        isDisabled={options.disabled}
        menuPlacement={menuPlacement}
        onFocus={onFocusHandle}
      />
    </Container>
  );
};

const m = React.memo(SelectWrapper);
export default m;
