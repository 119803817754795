import * as React from 'react';
import styled from 'styled-components';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BsCalendar } from 'react-icons/bs';
import ko from 'date-fns/locale/ko';
import classNames from 'classnames';

const Container = styled.span<{ width?: number }>`
  display: inline-block;
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  .react-datepicker__header {
    background-color: #fff;
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^='bottom'] {
    border-top: none;
    border-bottom-color: #fff;
    :after {
      border-bottom-color: #fff;
    }
  }

  .react-datepicker--time-only .react-datepicker__triangle {
    left: -36px !important;
  }

  .react-datepicker__day .react-datepicker__day-name {
    width: 2rem;
    line-height: 2rem;
    margin: 0.2rem;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 1rem;
    background-color: #4690dd;
  }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    border-radius: 1rem;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    padding: 8px;
    font-size: 1rem;
  }
  .react-datepicker__navigation {
    top: 10px;
  }
  .react-datepicker__header {
    border-bottom: none;
  }
  .react-datepicker__month-container {
    .react-datepicker__month-text {
      display: inline-block;
      width: 3rem;
      height: 2rem;
      line-height: 2rem;
      margin: 3px;
    }
    .react-datepicker__month--selected,
    .react-datepicker__month-text--keyboard-selected {
      background-color: #4690dd;
      border-radius: 1rem;
    }
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    display: flex;
    align-items: center;
    justify-content: center;
    &.react-datepicker__time-list-item--selected {
      background-color: #4690dd;
    }
  }

  &.isDisabledNone {
    .react-datepicker__time-list-item--disabled {
      display: none !important;
    }
  }

  .customCal {
    display: flex;
  }
  .withTime {
    &.customCal {
      left: 8px;
    }
  }
`;

const DateButton = styled.button<{ isOneDir?: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  line-height: 20px;
  padding: 5px 10px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 15px;
  cursor: pointer;
  .icon {
    display: flex;
    font-size: 20px;
    cursor: pointer;
    margin-right: 10px;
  }
  .placeholder {
    color: var(--brown-grey);
    font-size: 12px;
  }
  &.isDisabled {
    cursor: not-allowed;
    background: none;
    .icon {
      display: none;
      font-size: 20px;
      cursor: not-allowed;
      margin-right: 10px;
    }
  }
`;

interface DatePickerProps {
  minTime?: Date;
  maxTime?: Date;
  dateFormat?: string;
  customInput?: any;
  selected: Date | null;
  onChange: (e: any) => void;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  startDate?: Date;
  endDate?: Date;
  selectsRange?: boolean;
  shouldCloseOnSelect?: boolean;
  onCalendarOpen?: () => void;
  onCalendarClose?: () => void;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  monthsShown?: number;
  showPreviousMonths?: boolean;
  selectsDisabledDaysInRange?: boolean;
  open?: boolean;
  onInputClick?: () => void;
  onClickOutside?: () => void;
  showMonthYearPicker?: boolean;
  showTimeSelect?: boolean;
  timeFormat?: string;
  timeIntervals?: number;
  timeCaption?: string;
  showTimeSelectOnly?: boolean;
  showWeekNumbers?: boolean;
  placeholderText?: string;
  autoOpen?: boolean;
  portalId?: string;
  isOneDir?: boolean;
  width?: number;
  includeTimes?: any;
  isDisabledNone?: boolean;
}

const DatePickerWrapper: React.FC<DatePickerProps> = (props) => {
  const {
    showTimeSelect,
    customInput,
    selected,
    onChange,
    disabled,
    minDate,
    maxDate,
    startDate,
    endDate,
    selectsRange,
    shouldCloseOnSelect,
    onCalendarOpen,
    onCalendarClose,
    selectsStart,
    selectsEnd,
    monthsShown,
    showPreviousMonths,
    selectsDisabledDaysInRange,
    open,
    onInputClick,
    onClickOutside,
    showMonthYearPicker,
    timeFormat,
    timeIntervals,
    timeCaption,
    dateFormat,
    showTimeSelectOnly,
    placeholderText,
    minTime,
    maxTime,
    autoOpen,
    portalId,
    isOneDir,
    width,
    includeTimes,
    isDisabledNone = false,
  } = props;

  registerLocale('ko', ko);
  // const ref = React.createRef<HTMLButtonElement>();
  const DefaultInput = React.forwardRef(({ onClick, value, placeholder }: any, ref: React.Ref<HTMLButtonElement>) => {
    const Custom = customInput;
    return (
      <DateButton
        className={classNames({
          isDisabled: disabled ? true : false,
        })}
        ref={ref}
        isOneDir={isOneDir}
        onClick={onClick}
      >
        {customInput ? (
          <>
            <span className={'icon'} onMouseUp={autoOpen && onClick}>
              <BsCalendar />
            </span>
            <Custom {...props} />
          </>
        ) : (
          <>
            <span className={'icon'} onMouseUp={autoOpen && onClick}>
              <BsCalendar />
            </span>
            <span
              className={classNames({
                value,
                placeholder: !value,
              })}
            >
              {value ? value : placeholder}
            </span>
          </>
        )}
      </DateButton>
    );
  });

  const datePickerProps: DatePickerProps = {
    showTimeSelect,
    dateFormat: dateFormat || 'yyyy-MM-dd',
    selected: selected,
    onChange: onChange,
    customInput: <DefaultInput />,
    disabled: typeof disabled === 'undefined' ? false : disabled,
    selectsRange: typeof selectsRange === 'undefined' ? false : selectsRange,
    shouldCloseOnSelect: typeof shouldCloseOnSelect === 'undefined' ? true : shouldCloseOnSelect,
    selectsStart: typeof selectsStart === 'undefined' ? false : selectsStart,
    selectsEnd: typeof selectsEnd === 'undefined' ? false : selectsEnd,
    onCalendarOpen: onCalendarOpen,
    onCalendarClose: onCalendarClose,
    onInputClick: onInputClick,
    onClickOutside: onClickOutside,
    monthsShown: monthsShown,
    showPreviousMonths: typeof showPreviousMonths === 'undefined' ? false : showPreviousMonths,
    selectsDisabledDaysInRange: typeof selectsDisabledDaysInRange === 'undefined' ? true : selectsDisabledDaysInRange,
    showMonthYearPicker: typeof showMonthYearPicker === 'undefined' ? false : showMonthYearPicker,
    timeFormat,
    timeIntervals,
    timeCaption,
    showTimeSelectOnly,
    placeholderText,
    minTime,
    maxTime,
    includeTimes,
  };
  if (minDate) {
    datePickerProps.minDate = minDate;
  }
  if (maxDate) {
    datePickerProps.maxDate = maxDate;
  }
  if (startDate) {
    datePickerProps.startDate = startDate;
  }
  if (endDate) {
    datePickerProps.endDate = endDate;
  }
  if (typeof open !== 'undefined') {
    datePickerProps.open = open;
  }
  if (portalId) {
    datePickerProps.portalId = portalId;
  }

  return (
    <Container
      className={classNames({ isDisabledNone: isDisabledNone })}
      width={width}
      onWheel={(e) => {
        e.stopPropagation();
      }}
    >
      <DatePicker
        locale={'ko'}
        {...datePickerProps}
        calendarClassName={`customCal ${dateFormat === 'yyyy-MM-dd HH:mm' && 'withTime'}`}
      />
    </Container>
  );
};

export default React.memo(DatePickerWrapper);
