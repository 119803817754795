/**
 * 멤버 스토어 컨트롤 훅
 * @version : 1.0
 * @author  : UX (gilhyun@gmail.com)
 */
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import member, { insertMember } from '../modules/Reducer/Member';
import { GetMember, UpdateMember, UpdateConcurrentStatus } from '../services/MemberService';
import { checkQRCode, disableQRCode } from 'services/SecurityService';

import { MemberProps } from '../constants/Types';
import useAuthToken from '../hooks/useAuthToken';
import { config } from 'process';

import { useTranslation } from 'react-i18next';

export default function useMemberActions() {
  const dispatch = useDispatch();
  const authToken = useAuthToken();
  /**다국어 처리 */
  const { t } = useTranslation('account');

  /**
   * 멤버 추가
   * Redux
   */
  const onInsertMember = useCallback((data: MemberProps) => dispatch(insertMember(data)), [dispatch]);

  /**
   * 멤버 정보 로드
   * API
   */
  const loadMember = async () => {
    try {
      const memberData = await GetMember();
      onInsertMember(memberData);

      return memberData;
    } catch (error) {
      const e = error as any;
      if (e?.data?.data === 'expired') {
        return;
      }

      authToken.logout();
    }
  };

  const updateMember = async (data: any) => {
    try {
      const member = await UpdateMember(data);
      onInsertMember(member);
    } catch (e) {}
  };

  const updateConcurrentMember = async (concurrent: boolean) => {
    try {
      const member = await UpdateConcurrentStatus(concurrent);
      if (member.config?.isConcurrentUser) {
        toast.info(t('account.security.concurrent.on') as string, { autoClose: 2000 });
      } else {
        toast.info(t('account.security.concurrent.off') as string, { autoClose: 2000 });
      }
      onInsertMember(member);
    } catch (e) {}
  };

  const offQRCode = async () => {
    try {
      const retQR = await disableQRCode();
      toast.info(t('account.security.second.off') as string, { autoClose: 2000 });
      onInsertMember(retQR);
    } catch (e) {}
  };

  return { onInsertMember, loadMember, updateMember, updateConcurrentMember, offQRCode };
}
