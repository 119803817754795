import styled from 'styled-components';
import React from 'react';
import { useTooltip, Arrow } from 'react-laag';
import * as Icons from '../../constants/Icons';

interface ChannelDataTypes {
  channel: string;
  name: string;
  active: boolean;
}

interface TooltipProps {
  content: string;
  children: any;
  position?:
    | 'TOP_CENTER'
    | 'BOTTOM_LEFT'
    | 'BOTTOM_RIGHT'
    | 'BOTTOM_CENTER'
    | 'TOP_LEFT'
    | 'TOP_RIGHT'
    | 'LEFT_BOTTOM'
    | 'LEFT_TOP'
    | 'LEFT_CENTER'
    | 'RIGHT_BOTTOM'
    | 'RIGHT_TOP'
    | 'RIGHT_CENTER'
    | 'CENTER'
    | undefined;
  width?: number;
  fixed?: boolean;
  channels?: ChannelDataTypes[] | undefined;
  isHtml?: boolean;
  margin?: string;
}

interface ChannelIconProps {
  icon: string;
  active: boolean;
}

const ChildrenContainer = styled.span`
  & > {
    display: inline-block;
  }
`;

const ChannelsContainer = styled.div`
  padding-top: 10px;
`;

const ChannelIcon = styled.span<ChannelIconProps>`
  display: inline-block;
  width: 25px;
  height: 25px;
  margin: 0 2.5px;
  text-align: center;

  // 해피톡 아이콘
  ${(props) =>
    props.icon === 'webchatting' &&
    !props.active &&
    `
        background-image: url(${Icons.ChannelIconHappytalk});
        background-image: image-set(
            url(${Icons.ChannelIconHappytalk}) 1x,
            url(${Icons.ChannelIconHappytalk2x}) 2x,
            url(${Icons.ChannelIconHappytalk3x}) 3x
        );
    `}

  ${(props) =>
    props.icon === 'webchatting' &&
    props.active &&
    `
        background-image: url(${Icons.ChannelIconHappytalkActive});
        background-image: image-set(
            url(${Icons.ChannelIconHappytalkActive}) 1x,
            url(${Icons.ChannelIconHappytalkActive2x}) 2x,
            url(${Icons.ChannelIconHappytalkActive3x}) 3x
        );
    `} // 카카오 아이콘
  ${(props) =>
    props.icon === 'kakao' &&
    !props.active &&
    `
        background-image: url(${Icons.ChannelIconKakao});
        background-image: image-set(
            url(${Icons.ChannelIconKakao}) 1x,
            url(${Icons.ChannelIconKakao2x}) 2x,
            url(${Icons.ChannelIconKakao3x}) 3x
        );
    `}

  ${(props) =>
    props.icon === 'kakao' &&
    props.active &&
    `
        background-image: url(${Icons.ChannelIconKakaoActive});
        background-image: image-set(
            url(${Icons.ChannelIconKakaoActive}) 1x,
            url(${Icons.ChannelIconKakaoActive2x}) 2x,
            url(${Icons.ChannelIconKakaoActive3x}) 3x
        );
    `} // 네이버 아이콘
  ${(props) =>
    props.icon === 'naver' &&
    !props.active &&
    `
        background-image: url(${Icons.ChannelIconNaver});
        background-image: image-set(
            url(${Icons.ChannelIconNaver}) 1x,
            url(${Icons.ChannelIconNaver2x}) 2x,
            url(${Icons.ChannelIconNaver3x}) 3x
        );
    `}

  ${(props) =>
    props.icon === 'naver' &&
    props.active &&
    `
        background-image: url(${Icons.ChannelIconNaverActive});
        background-image: image-set(
            url(${Icons.ChannelIconNaverActive}) 1x,
            url(${Icons.ChannelIconNaverActive2x}) 2x,
            url(${Icons.ChannelIconNaverActive3x}) 3x
        );
    `} // 라인 아이콘
  ${(props) =>
    props.icon === 'line' &&
    !props.active &&
    `
        background-image: url(${Icons.ChannelIconLine});
        background-image: image-set(
            url(${Icons.ChannelIconLine}) 1x,
            url(${Icons.ChannelIconLine2x}) 2x,
            url(${Icons.ChannelIconLine3x}) 3x
        );
    `}

  ${(props) =>
    props.icon === 'line' &&
    props.active &&
    `
        background-image: url(${Icons.ChannelIconLineActive});
        background-image: image-set(
            url(${Icons.ChannelIconLineActive}) 1x,
            url(${Icons.ChannelIconLineActive2x}) 2x,
            url(${Icons.ChannelIconLineActive3x}) 3x
        );
    `} // 페이스북 아이콘
  ${(props) =>
    props.icon === 'facebook' &&
    !props.active &&
    `
        background-image: url(${Icons.ChannelIconFacebook});
        background-image: image-set(
            url(${Icons.ChannelIconFacebook}) 1x,
            url(${Icons.ChannelIconFacebook2x}) 2x,
            url(${Icons.ChannelIconFacebook3x}) 3x
        );
    `}

  ${(props) =>
    props.icon === 'facebook' &&
    props.active &&
    `
        background-image: url(${Icons.ChannelIconFacebookActive});
        background-image: image-set(
            url(${Icons.ChannelIconFacebookActive}) 1x,
            url(${Icons.ChannelIconFacebookActive2x}) 2x,
            url(${Icons.ChannelIconFacebookActive3x}) 3x
        );
    `}
`;

function Tooltip({ content, position, width, fixed, channels, children, isHtml = false, margin }: TooltipProps) {
  const [element, triggerProps] = useTooltip(
    ({ isOpen, layerProps, layerSide, arrowStyle }) =>
      isOpen && (
        <div
          {...layerProps}
          style={{
            ...layerProps.style,
            zIndex: 9999990,
            backgroundColor: '#1a1a1a',
            color: '#fff',
            padding: '5px 10px',
            fontSize: 13,
            lineHeight: '18px',
            borderRadius: 5,
            border: '1px solid #1a1a1a',
            boxShadow: 'rgba(210, 210, 210, 0.5) 0px 0px 3px 0px',
            width: width ? width : 'auto',
            whiteSpace: 'pre-line',
            margin: margin,
            wordBreak: width ? 'break-all' : undefined,
          }}
        >
          <Arrow
            style={arrowStyle}
            layerSide={layerSide}
            size={6}
            angle={45}
            roundness={1}
            borderWidth={1}
            borderColor="#1a1a1a"
            backgroundColor="#1a1a1a"
          />
          {content}
          {channels && (
            <ChannelsContainer>
              {channels.map((channel: ChannelDataTypes, key: number) => (
                <ChannelIcon key={key} icon={channel.channel} active={channel.active} />
              ))}
            </ChannelsContainer>
          )}
        </div>
      ),
    {
      delayEnter: 100,
      delayLeave: 100,
      placement: {
        anchor: position || 'TOP_CENTER',
        snapToAnchor: true,
        triggerOffset: 12,
      },
      fixed: fixed ? fixed : false,
    },
  );

  return (
    <>
      {element}
      <ChildrenContainer {...triggerProps}>{children}</ChildrenContainer>
    </>
  );
}

export default React.memo(Tooltip);
