const AR_SET_CONFIG = 'ar/GET_CONFIG' as const;
const AR_SET_ALIMTALK_TEMP = 'ar/GET_ALIMTALK_TEMP' as const;
const AR_SET_INCOMMING_GROUP = 'ar/GET_INCOMMING_GROUP' as const;
const AR_SET_TEXT_USAGE = 'ar/GET_TEXT_USAGE' as const;
const AR_SET_VOICE_OPTIONS = 'ar/GET_VOICE_OPTIONS' as const;
const AR_SET_OUTCALLNUMBER = 'ar/SET_OUTCALLNUMBER' as const;
const AR_SET_CURRENT_PLAY_BLOCK_ID = 'ar/SET_CURRENT_PLAY_BLOCK_ID' as const;

interface AR_CONFIG {
  serviceType: string;
  countLimitTts: string;
  isTextMessage: string;
  countLimitCall: string;
}

interface AR_TEXT_USAGE {
  period: string; // YYYYMM
  textUsage: number;
}

interface AlimBtn {
  name: string;
  type: string;
}
interface AR_AlimTemp {
  id: string;
  code: string;
  content: string;
  name: string;
  buttons: AlimBtn[];
}

export interface AR_Incomming {
  id: string;
  title: string;
  count?: string;
}

export interface AR_Voice_Options {
  VoiceSpeaker: any[];
  VoicePitch: any[];
  VoiceSpeed: any[];
}

export interface ARProps {
  config?: AR_CONFIG;
  alimTemp?: AR_AlimTemp[];
  incommingGroup?: AR_Incomming[];
  usage?: AR_TEXT_USAGE;
  voiceOptions?: AR_Voice_Options;
  outCallNumber: string;
  currentPlayBlockId: string;
}

const incommingDefaultData: AR_Incomming = {
  id: 'default',
  title: '기본',
  count: '0',
};
const initialState: ARProps = {
  incommingGroup: [incommingDefaultData],
  outCallNumber: '',
  currentPlayBlockId: '',
};

export const setARConfig = (data: AR_CONFIG) => ({
  type: AR_SET_CONFIG,
  payload: data,
});

export const setARAlimTemp = (data: AR_AlimTemp[]) => ({
  type: AR_SET_ALIMTALK_TEMP,
  payload: data,
});

export const setARIncommingGroup = (data: { total: string; list: AR_Incomming[] }) => ({
  type: AR_SET_INCOMMING_GROUP,
  payload: data,
});

export const setThisMonthTextUsage = (data: AR_TEXT_USAGE) => ({
  type: AR_SET_TEXT_USAGE,
  payload: data,
});

export const setVoiceOptions = (data: AR_Voice_Options) => ({
  type: AR_SET_VOICE_OPTIONS,
  payload: data,
});

export const setAROutCallNumber = (data: string) => ({
  type: AR_SET_OUTCALLNUMBER,
  payload: data,
});

export const setCurrentPlayBlockId = (data: string) => ({
  type: AR_SET_CURRENT_PLAY_BLOCK_ID,
  payload: data,
});

type ARActionType =
  | typeof setARConfig
  | typeof setARAlimTemp
  | typeof setARIncommingGroup
  | typeof setThisMonthTextUsage
  | typeof setVoiceOptions
  | typeof setAROutCallNumber
  | typeof setCurrentPlayBlockId;

function AR(state: ARProps = initialState, action: ReturnType<ARActionType>) {
  switch (action.type) {
    case AR_SET_OUTCALLNUMBER:
      return {
        ...state,
        outCallNumber: action.payload,
      };
    case AR_SET_CONFIG:
      return {
        ...state,
        config: action.payload,
      };

    case AR_SET_ALIMTALK_TEMP:
      return {
        ...state,
        alimTemp: action.payload,
      };

    case AR_SET_INCOMMING_GROUP:
      incommingDefaultData.count = String(action.payload.total || '0');
      return {
        ...state,
        incommingGroup: [incommingDefaultData, ...action.payload.list],
      };

    case AR_SET_TEXT_USAGE:
      return {
        ...state,
        usage: action.payload,
      };

    case AR_SET_VOICE_OPTIONS:
      return {
        ...state,
        voiceOptions: action.payload,
      };

    case AR_SET_CURRENT_PLAY_BLOCK_ID:
      return {
        ...state,
        currentPlayBlockId: action.payload,
      };

    default: {
      return state;
    }
  }
}

export default AR;
