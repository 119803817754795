import styled from 'styled-components';
import React, { useRef, useEffect } from 'react';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import * as Icons from '../../constants/Icons';

import { useTable, useSortBy, useFlexLayout, useResizeColumns, useGlobalFilter, useExpanded } from 'react-table';
import { Input } from 'modules/Styled';

const LoadingImg = styled.div`
  position: fixed;
  width: 70px;
  height: 70px;
  left: 50%;
  top: 50%;
  background-size: contain;
  transform: translate(-50%, -50%);
  background-image: url(${Icons.LoadingGif});
  background-image: image-set(
    url(${Icons.LoadingGif}) 1x,
    url(${Icons.LoadingGif2x}) 2x,
    url(${Icons.LoadingGif3x}) 3x
  );
`;
const TableWrapper = styled.div<{ maxHeight?: number; sortTop?: number; padding?: string }>`
  user-select: text;
  padding: ${(props) => props.padding || '1rem'};
  overflow-x: hidden;

  :hover {
    overflow-x: overlay;
  }

  ::-webkit-scrollbar {
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: #ccc;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #aaa;
    background-clip: content-box;
  }

  .globalSearch {
    float: right;
    width: 200px;
    margin: 5px 0;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      float: left;
      color: #c0bfc0;
      box-shadow: inset 0 -1px 0 0 #eaeaea;
      background-color: #fcfcfc;
    }

    tbody {
      float: left;
      display: block;
      overflow-y: hidden;
      max-height: ${(props) => (props.maxHeight ? `${props.maxHeight}px` : 'auto')};
      width: 100%;

      .expandedArea:hover {
        background: #fff;
      }
    }

    tbody:hover {
      overflow-y: overlay;
    }

    tbody::-webkit-scrollbar {
      width: 0;
    }

    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
      height: 32px;
    }

    tbody tr {
      height: auto;
      box-shadow: inset 0 -1px 0 0 #eaeaea;

      &.last {
        height: 1px;
      }

      :last-child {
        box-shadow: none;
      }

      :hover {
        background-color: #f4f7ff;

        .rollbackBtn {
          opacity: 1;
        }
      }
    }

    /* tbody tr:nth-child(odd) {
            background: #EEE;
            :hover {
                background-color: #E9F1FE;
            }
        } */

    th,
    td {
      margin: 0;
      padding: 9px 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      white-space: nowrap;
      color: #1a1a1a;
      font-size: 13px;
      font-family: Noto Sans KR;
      line-height: 1.61;

      .resizer {
        background: #eaeaea;
        display: inline-block;
        width: 2px;
        height: calc(100% - 16px);
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        // z-index: 1;
        touch-action: none;
        margin: 8px 0;
      }

      &:last-child {
        .resizer {
          display: none;
        }
      }

      .sortIcon {
        position: absolute;
        right: 10px;
        top: ${(props) => (props.sortTop ? `${props.sortTop}px` : '12px')};
      }

      &.isBtn {
        padding: 7px 20px;
      }
    }
  }
`;

interface TableProps {
  columns: any;
  data: any[];
  emptyComment?: string;
  resize?: boolean;
  getRowProps?: any;
  getCellProps?: any;
  getHeaderProps?: any;
  getColumnProps?: any;
  scroll?: boolean;
  globalSearch?: boolean;
  maxHeight?: number;
  sortTop?: number;
  isAddLoad?: (u: boolean) => void;
  renderRowSubComponent?: any;
  isLoading?: any;
  hiddenColumns?: string[];
  sortBy?: { id: string; desc: boolean }[];
  padding?: string;
}

const defaultPropGetter = () => ({});

const Table: React.FC<TableProps> = ({
  columns: userColumns,
  data,
  emptyComment,
  resize,
  globalSearch,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  scroll,
  isAddLoad,
  maxHeight,
  sortTop,
  renderRowSubComponent,
  isLoading,
  hiddenColumns,
  sortBy,
  padding,
}) => {
  /** 테이블 사이즈 조절 확인 */
  const [tableProps, setTableProps] = React.useState<any>([useSortBy, useResizeColumns]);
  useEffect(() => {
    if (resize) {
      setTableProps([...tableProps, useFlexLayout]);
    }
  }, [resize]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    visibleColumns,
    state: { expanded },
  } = useTable(
    {
      columns: userColumns,
      data,
      initialState: { hiddenColumns: hiddenColumns || [], sortBy: sortBy || [] },
    },
    useGlobalFilter,
    ...tableProps,
    useExpanded,
  );

  const [globalFilter, setGlobalFilterName] = React.useState('');
  const viewPort = useRef(null);
  const target = useRef(null);

  useEffect(() => {
    const options = {
      root: viewPort.current,
      threshold: 0,
    };

    const handleInterSection = (entries: any, observer: any) => {
      entries.forEach((entry: any) => {
        if (!entry.isIntersecting) {
          return;
        }
        if (isAddLoad) {
          isAddLoad(true);
          return;
        }
        observer.unobserve(entry.target);
        observer.observe(target.current);
      });
    };

    const io = new IntersectionObserver(handleInterSection, options);

    if (target.current) {
      io.observe(target.current as any);
    }

    return () => io && io.disconnect();
  }, [viewPort, target]);

  if (headerGroups.length > 1) {
    const temp: any[] = [];
    headerGroups[0].headers = headerGroups[0].headers.filter(({ depth }, i) => {
      if (depth !== undefined) return true;
      temp.push(i);
      return false;
    });

    headerGroups[1].headers = headerGroups[1].headers.filter((header) => {
      if (header.parent !== undefined) return true;
      headerGroups[0].headers.splice(temp.shift(), 0, header);

      return false;
    });
  }
  return (
    <TableWrapper maxHeight={maxHeight || 550} sortTop={sortTop || 11} padding={padding}>
      {globalSearch && (
        <Input
          className="globalSearch"
          type="text"
          value={globalFilter || ''}
          onChange={(e) => {
            setGlobalFilter(e.target.value);
            setGlobalFilterName(e.target.value);
          }}
        />
      )}
      {isLoading && <LoadingImg />}
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => {
                if (column.depth === undefined) return;
                const headerProps = [
                  {
                    className: column.className,
                    style: { ...column.style, verticalAlign: 'middle' },
                  },
                  getColumnProps(column),
                  getHeaderProps(column),
                ];
                if (column.useSort) {
                  headerProps.push(column.getSortByToggleProps({ title: undefined }));
                }
                return (
                  <th {...column.getHeaderProps(headerProps)} rowSpan={column.rowspan || 1}>
                    {column.render('Header')}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className={'sortIcon down'}>
                          <TiArrowSortedDown />
                        </span>
                      ) : (
                        <span className={'sortIcon up'}>
                          <TiArrowSortedUp />
                        </span>
                      )
                    ) : (
                      ''
                    )}
                    {resize && (
                      <div
                        {...column.getResizerProps()}
                        className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      />
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} ref={viewPort}>
          {!rows.length && emptyComment && (
            <tr>
              <td>{emptyComment}</td>
            </tr>
          )}
          {rows.map((row: any, i: number) => {
            prepareRow(row);
            const rowProps = row.getRowProps();
            return (
              <React.Fragment key={rowProps.key}>
                <tr {...row.getRowProps(getRowProps(row))}>
                  {row.cells.map((cell: any) => {
                    return (
                      <td
                        {...cell.getCellProps([
                          {
                            className: cell.column.className,
                            style: cell.column.style,
                            title: cell.column.title ? cell.value : null,
                            type: cell.column.notRowClickEvent ? 'notClick' : null,
                          },
                        ])}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
                {row.isExpanded ? (
                  <tr className={'expandedArea'}>
                    <td colSpan={visibleColumns.length}>{renderRowSubComponent({ row })}</td>
                  </tr>
                ) : null}
              </React.Fragment>
            );
          })}
          {scroll && <tr className={`row last`} ref={target} />}
        </tbody>
      </table>
    </TableWrapper>
  );
};

export default React.memo(Table);
